// @ts-ignore
if (!window.APPSettings) {
  // @ts-ignore
  window.APPSettings = {
    realmSettings: {
      url: 'https://localhost:8080/auth',
      realm: 'customer',
      clientId: 'web-portal',
    },
    showBuildVersion: true,
    name: 'test',
  };
}

// @ts-ignore
export default window.APPSettings;
